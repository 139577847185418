.cardTitle {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    background-color: #A9CBFF !important;
}

.cardBody {
    display: flex;
    justify-content: center;
}

.tableCard {
    margin: 10px;
    width: fit-content;
    background-color: #dceaff !important;
}

th {
    font-weight: bold;
}

td:first-child {
    font-weight: bold;
}

.table {
    width: auto !important;
    margin-bottom: 0.7rem;
    --bs-table-bg: #dceaff  !important;
    --bs-table-border-color: grey !important;
}

.tableContainer {
    display: flex;
    justify-content: center;
}

.checkboxesContainer {
    display: flex;
    justify-content: center;
    gap: 5px;
}
