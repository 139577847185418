.textLine {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
  font-family: fangsong;
}

.verse {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(var(--bs-primary), white);
  height: calc(100% - 56px);
  width: 100%;
}